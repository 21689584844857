/* Add your custom JavaScript code */


function updateCartCount() {
    $.ajax({
        url: "/cart/count",
        success: function (data, status) {
            $(".cart-notification").text(data.result)
        }
    })
}

function addToCart(obj) {
    var $url = '/cart/add/'
    $.post($url, obj)
        .done(function(r){
            $(document).trigger('cartUpdate')
            swal({
                title: '商品已加入購物車。',
                icon: 'success',
                text: '接下來您要？',
                buttons: {
                    cancel: "繼續購物",
                    confirm: {
                        text: "前往結帳",
                        value: 'confirm',
                        closeModal: true
                    }
                }
            }).then(redirect)
        })
        .fail(function(xhr, r) {
            if (parseInt(xhr.status) === 401) return window.location.href = "/user/login";
            var response = xhr.responseJSON;
            swal({
                title: 'Oops!',
                text: response.message,
                icon: 'warning',
                buttons: {
                    cancel: "繼續購物",
                    confirm: {
                        text: "前往結帳",
                        value: 'confirm',
                        closeModal: true
                    }
                }
            }).then(redirect)
        })
}

function redirect(confirm) {
    if (confirm) {
        window.location = "/cart"
    } else {
        return false
    }
}


$(function() {
    var $URL = window.location.toString().replace('#', ''),
        $MENU = $('#main-menu')

    $MENU.find('a').filter(function () {
        return this.href === $URL;
    }).parent().addClass('current')
})


$(document).on("cartUpdate", updateCartCount);
$(document).trigger("cartUpdate");